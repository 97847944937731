<template>
  <div v-if="role !== 'admin'" class="mt-5">
    <card type="gradient-primary" class="mt-2">
      <h3 class="mb-0 text-white">{{$t("userPromoPlan.helpUsToHelpYou")}}</h3>
      <small class="text-white text-muted mt-1"
        >{{$t("userPromoPlan.ifYoureExperiencingAnyIssuesOrYouLikeToShareYourInsightfulFeedback")}} </small
      >
      <base-button
        class="ml-5 mt-3 text-white"
        type="success"
        size="md"
        @click="redirect()"
        >{{$t("userPromoPlan.contactUsNow")}}</base-button
      >
    </card>

    <!--    <card type="gradient-primary" class="mt-3">-->
    <!--      <h5 class="text-white">!</h5>-->
    <!--      <h6 class="text-white">Don't hesitate to contact us and provide your valuable feedback!</h6>-->

    <!--      <base-button outline class="ml-3 mt-2 text-white" type="info" @click="redirect()">Contact us!</base-button>-->
    <!--    </card>-->
    <!-- <base-alert
        v-if="role === 'developer' || role === 'member'"
        type="warning">
      <strong>Plan Upgrade!</strong> <br>
      Upgrade to 'Creator' plan for only 9.99 € to host private and paid events!

      <base-button class="ml-3 mt-2" type="primary">Upgrade me!</base-button>

    </base-alert>

    <base-alert
        v-if="role === 'developer' || role === 'creator'"
        type="warning">
      <strong>Plan Upgrade!</strong> <br>
      Upgrade to 'Organizer' plan for only 14.99 € to create robust events with paid sponsors and robust events!

      <base-button class="ml-3 mt-2" type="primary">Upgrade me!</base-button>

    </base-alert>

    <base-alert
        v-if="role === 'developer' || role === 'planner'"
        type="warning">
      <strong><u>Plan Upgrade!</u></strong> <br>
      Upgrade to 'Organizer' plan for only 14.99 € to create robust events with paid sponsors and robust events!

      <base-button class="ml-3 mt-2" type="primary">Upgrade me!</base-button>

    </base-alert>

    <base-alert
        v-if="role === 'developer' || role === 'organizer'"
        type="warning">
      <strong>Plan Upgrade!</strong> <br>
      Upgrade to 'Planner' plan for only 19.99 € to create personalized multipurpose events !

      <base-button class="ml-3 mt-2" type="primary">Upgrade me!</base-button>
    </base-alert> -->
  </div>
</template>

<script>
export default {
  name: "UserPromoPlan",
  props: {
    role: {
      type: String,
      default: "member",
      description: "User role (admin|creator|member|etc.)",
    },
  },
  methods: {
    redirect() {
      // window.open('https://forms.gle/6XhuWGjiwyGgAgdx7', "_blank");
      // Hubspot Support form
      window.open("https://www.bookmeinscotty.com/feedback-form/", "_blank");
    },
  },
};
</script>

<style scoped></style>
